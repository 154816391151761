import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { TaxMappingProvider } from "presentation/store/TaxMapping/TaxMappingProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import TaxMappingMaintenance from "presentation/view/section/TaxMapping/TaxMappingMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const TaxMappingContMain = () => {
    return <ANAInfoWrapper permission={Permission.TAX_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <TaxMappingProvider>
                <GridStyles/>
                <TaxMappingMaintenance/>
            </TaxMappingProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default TaxMappingContMain;
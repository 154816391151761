import { EMPTY_TAX_MAPPING_ENTITY, TaxMappingEntity } from "domain/entity/TaxMapping/TaxMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TaxMappingDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    chargeTariffTypeDropdownOptions: DropdownProps[],
    chargeTariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    consortiumCodeDropdownOptions: DropdownProps[],
    companyCodeDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
    taxCodeDropdownOptions: DropdownProps[],
    vatArticleStatementCodeDropdownOptions: DropdownProps[],
    regionDropdownOptions: DropdownProps[],
    groupDropdownOptions: DropdownProps[],
    countryDropdownOptions: DropdownProps[],
}
export interface TaxMappingViewChangeState extends BaseViewChangeSate {
}

export interface TaxMappingModel {
    isLoading:boolean,
    isShowEditPanel:boolean,
    isAdd:boolean,
    isEdit:boolean,
    dynamicOptions: TaxMappingDropdownOptions,
    taxMappingList: TaxMappingEntity[],
    selectedRows: TaxMappingEntity[],
    updatedRows: TaxMappingEntity[],
    currentRow: TaxMappingEntity,
    currentEditRow: TaxMappingEntity,
    transactionTypeList: { [key: string]: string },
    articleStatementList: { [key: string]: string },
    vatList: { [key: string]: number },
    
}

export const EMPTY_TAX_MAPPING_MODEL: TaxMappingModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        companyCodeDropdownOptions: [],
        serviceCodeDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        chargeTariffTypeDropdownOptions: [],
        chargeTariffCodeDropdownOptions: {},
        consortiumCodeDropdownOptions: [],
        taxCodeDropdownOptions: [],
        vatArticleStatementCodeDropdownOptions: [],
        regionDropdownOptions: [],
        groupDropdownOptions: [],
        countryDropdownOptions: []
    },
    taxMappingList: [],
    selectedRows: [],
    updatedRows: [],
    currentRow: { ...EMPTY_TAX_MAPPING_ENTITY },
    currentEditRow: { ...EMPTY_TAX_MAPPING_ENTITY },
    transactionTypeList: {},
    articleStatementList: {},
    vatList: {}
}
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { TaxMappingEntity } from "domain/entity/TaxMapping/TaxMappingEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { TaxMappingRepository } from "./TaxMappingRepo";

export const TaxMappingRepoImpl = (): TaxMappingRepository => {
    
    const searchUrl = "/v1/searchAllVatMappingList"

    const searchAllTaxMappingList = async() : Promise<TaxMappingEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const onApply = async(rows: TaxMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/vatMappingApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    const onSave = async(row: TaxMappingEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/vatMappingSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }

    const uploadData =async(csvData: TaxMappingEntity[]): Promise<ResponseEntity> => {
        const uploadUrl = "/v1/vatMappingUpload"
        return await axiosPostData(referenceAxiosInstance, `${uploadUrl}`, csvData);
    }

    return {
        uploadData: uploadData,
        onSave: onSave,
        onApply: onApply,
        searchAllTaxMappingList : searchAllTaxMappingList,
    }
}


export const TaxMappingConstant = {
    Table: {
        PRIORITY: "Priority",
        BILL_TO_COMPANY: "Bill To Company",
        CHARGE_TYPE: "Charge Type",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        CONSORTIUM_CODE: "Consortium Code",
        SERVICE_CODE: "Service Code",
        CHARGE_TAR_TYPE: "Charge Tar Type",
        CHARGE_TAR_CODE: "Charge Tar Code ",
        CHARGE_TAR_CODE_DESC: "Charge Tar Code Desc",
        TAX_TARIFF_TYPE: "Tax Tariff Type",
        TAX_TARIFF_CODE: "Tax Tariff Code",
        TAX_TARIFF_CODE_DESC: "Tax Tariff Code Desc.",
        TAX_CODE: "Tax Code",
        ARTICLE_STATEMENT_CODE: "Article Statement Code",
        COUNTRY: "Country",
        DOC_TYPE: "Doc Type",
        TAX_PAYMENT_REFERENCE_DATEINVOICE: "Tax Payment Reference Date(Invoice)",
        TAX_PAYMENT_TERMINVOICE: "Tax Payment Term(Invoice)",
        TAX_PAYMENT_REFERENCE_DATECREDIT: "Tax Payment Reference Date(Credit)",
        TAX_PAYMENT_TERMCREDIT: "Tax Payment Term(Credit)",
        TAX_PAYMENT_REFERENCE_DATEDEBIT: "Tax Payment Reference Date(Debit)",
        TAX_PAYMENT_TERMDEBIT: "Tax Payment Term(Debit)",
        TAX: "Tax %",
        TRANSACTION_TYPE: "Transaction Type",
        ARTICLE_STATEMENT: "Article Statement",
        EFFECTIVE_DATE: "Effective Date",
        REGION: "Region",
        GROUP_ID: "Group ID",
        ACTIVE_IND: "Active Ind.",
    },
    Title: {
        CATEGORY: "Invoice",
        TITLE: "Tax",
        MAPPING_FROM: "From",
        MAPPING_TO: "To",
    },
}
export const taxMappingRequiredFieldList: string[] = [
    'percentage',
    'tariffType',
    'tariffCode',
    'taxCode',
    'paymentTerm',
    'paymentTermCredit',
    'paymentTermDebit',
    'effectiveDate',
    'paymentReferenceDateDebit',
    'paymentReferenceDateCredit',
    'paymentReferenceDate',
    'statementCode',
    'activeInd',
];

export const taxMappingCSVColumnMapping: string[] = [
    'billToCompanyCode',
    'chargeType',
    'subChargeType',
    'consortiumCode',
    'serviceCode',
    'chargeTariffType',
    'chargeTariffCode',
    'tariffType',
    'tariffCode',
    'taxCode',
    'statementCode',
    'country',
    'docType',
    'paymentReferenceDate',
    'paymentTerm',
    'paymentReferenceDateCredit',
    'paymentTermCredit',
    'paymentReferenceDateDebit',
    'paymentTermDebit',
    'percentage',
    'effectiveDate',
    'region',
    'groupId',
    'activeInd',
];